import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import "../Logistics/Logistics.scss";
import ServiceToolbar from "../ServiceToolbar";

const Loading = () => {
  return (
    <>
      <div className="logistics-con">
      <div>
        <p className="title">
        <StaticImage
          className="service-badge"
          src="../../../images/svg/loading.svg"
          width={45}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="badge"
        />
        Loading
        </p>
      </div>

        <div className="d-flex flex-row service-ch-land">
          <div className="logistics-left">
            <div className="logistics">
              <StaticImage
                  className="ch-img"
                  src="../../../images/loading-truck.png"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text">
                {/* <p className="label">Stevedoring & Arrastre</p> */}
                <p>
                  Subic Grain's loading services ensures the efficient and effective
                  loading of cargo unto the client's trucks, be it via 50kgs bags or
                  in bulk. In continuously seeking out ways to streamline our loading
                  operations, we make sure that trucks spend the minimal amount of
                  time possible in our facility whilst enjoying a first come, first
                  served basis in loading priority.
                </p>
                <br/>
{/* 
                <p>
                      <ul>
                        <li>Discharge Rate (per wwd) 100 Trucks at 35 Tons per Truck</li>
                        <li>Geared 7,500 MT / Day</li>
                        <li>Gearless 4,500 MT / Day</li>
                      </ul>
                </p> */}
              </div>

            </div>
          </div>
        </div>
      </div>

      <ServiceToolbar />
    </>
  );
};

export default Loading;
